<template>
  <div class="container-fluid container">
    <el-dialog
      title="這些名稱包含兩所學校，請選擇指定學校："
      :visible.sync="choiceCollegeAttachLinkDialog"
      width="600px"
    >
      <div
        class="collegeName"
        :key="collegeName"
        v-for="(collegeName) in collegeNames"
      >
        <div v-if="colleges[collegeName].length > 1">
          <b>{{ collegeName }}</b>
          <div class="matchColleges">
            <div :key="college.id" v-for="college in colleges[collegeName]">
              <el-checkbox
                :value="college.selected"
                @input="() => selectedCollege(collegeName, college.id)"
              />
              <span v-if="lang === 'EN-US'">{{ college.name[langList[lang]] }}</span>
              <span v-else>
                {{ college.name[langList[lang]] }}&nbsp;({{ college.name["en"] }})
              </span>
              <span>{{ `${college.city}, ${college.state}.` }}</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="() => (choiceCollegeAttachLinkDialog = false)"
        >
          {{ $t("button.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <Breadcrumb />
    <h1>{{ pageTitle }}</h1>
    有時候行政人員會需要自己手動將各個大學的連結編輯到大學的名稱上面，這裡可以一次將多個大學的名稱或是名稱加上屬於該大學的連結。
    <hr class="separate-line" />
    <el-form @submit.prevent.native="fetchSchools">
      <el-form-item>
        <template slot="label">
          請貼上大學名字（一個為一行）
        </template>
        <el-input type="textarea" :rows="6" v-model="collegesString" />
      </el-form-item>
      <el-button type="primary" native-type="submit">
        Submit
      </el-button>
    </el-form>
    <hr class="separate-line" />
    <div v-if="collegeNames.length !== 0">
      <table>
        <tr :key="collegeName" v-for="collegeName in collegeNames">
          <td>
            <span v-if="colleges[collegeName].length === 0">
              {{ collegeName }}
            </span>
            <template v-else>
              <a
                :href="`https://ivy-way.com/schools/${getCollegeSlug(collegeName)}`"
                >{{ collegeName }}</a
              >
            </template>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import schoolApi from "@/apis/school.js";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.attachLinkToSchool");
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    collegeNames() {
      return Object.keys(this.colleges);
    }
  },
  data() {
    return {
      collegesString: "",
      choiceCollegeAttachLinkDialog: false,
      colleges: {}
    };
  },
  methods: {
    async fetchSchools() {
      const { schools: colleges } = await schoolApi.getSchoolByNameOrAlias(this.collegesString);
      this.colleges = colleges;
      this.collegeNames.forEach(collegeName => {
        if (colleges[collegeName].length > 0) {
          colleges[collegeName][0].selected = true;
        }
      });
      if (this.hasNameMatchMultipleCollege()) {
        this.choiceCollegeAttachLinkDialog = true;
      }
    },
    hasNameMatchMultipleCollege() {
      return this.collegeNames.some(collegeName => (
        this.colleges[collegeName].length >= 2
      ));
    },
    selectedCollege(collegeName, collegeId) {
      this.colleges = {
        ...this.colleges,
        [collegeName]: this.colleges[collegeName].map(college => ({
          ...college,
          selected: college.id === collegeId
        }))
      };
    },
    getCollegeSlug(collegeName) {
      const college = this.colleges[collegeName].find(
        college => college.selected
      );
      return college.slug;
    }
  }
};
</script>

<style scoped>
.container {
  margin-bottom: 40px;
}

.collegeName {
  margin: 8px 0px;
}

.matchColleges {
  margin-left: 20px;
}

.matchColleges > .title {
  color: #696969;
  font-size: 14px;
}
</style>